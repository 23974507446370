// Generated by Framer (2b47498)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, getLoadingLazyAtYPosition, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/h8CzwBybC";

const enabledGestures = {QPTvDvELV: {hover: true}};

const serializationHash = "framer-Jx9jY"

const variantClassNames = {QPTvDvELV: "framer-v-iz5mq8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, e8JeMkAVh: text ?? props.e8JeMkAVh ?? "hello@capable.com", IIsmGTaJn: link ?? props.IIsmGTaJn} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, IIsmGTaJn, e8JeMkAVh, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "QPTvDvELV", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={IIsmGTaJn} nodeId={"QPTvDvELV"}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-iz5mq8", className, classNames)} framer-1ud4nat`} data-border data-framer-name={"Email Button"} layoutDependency={layoutDependency} layoutId={"QPTvDvELV"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-65cf6e29-66fa-4301-ba74-9b3cb0ba65fb, rgb(221, 229, 237))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-d4a016e4-fc9c-4b27-bade-c0fbf4227f4f, rgb(244, 247, 249))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{"QPTvDvELV-hover": {backgroundColor: "var(--token-a472470e-9869-4232-abc7-388dd856cc79, rgb(238, 241, 246))"}}} {...addPropertyOverrides({"QPTvDvELV-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "Icon", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (12 + ((((componentViewport?.height || 51) - 24) - 24) / 2)))), pixelHeight: 24, pixelWidth: 24, src: "https://framerusercontent.com/images/vuJM4WYylsGEThrsXHFXAAbFo.svg"}} className={"framer-152161j"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"f4Q_M6u_i"}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-wh6ug7"} data-styles-preset={"h8CzwBybC"}>hello@capable.com</motion.p></React.Fragment>} className={"framer-sttsrz"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"eBHhChjw0"} style={{"--framer-paragraph-spacing": "0px"}} text={e8JeMkAVh} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Jx9jY.framer-1ud4nat, .framer-Jx9jY .framer-1ud4nat { display: block; }", ".framer-Jx9jY.framer-iz5mq8 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: flex-start; overflow: visible; padding: 12px 16px 12px 16px; position: relative; text-decoration: none; width: min-content; }", ".framer-Jx9jY .framer-152161j { flex: none; height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-Jx9jY .framer-sttsrz { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Jx9jY.framer-iz5mq8 { gap: 0px; } .framer-Jx9jY.framer-iz5mq8 > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-Jx9jY.framer-iz5mq8 > :first-child { margin-left: 0px; } .framer-Jx9jY.framer-iz5mq8 > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ".framer-Jx9jY[data-border=\"true\"]::after, .framer-Jx9jY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 230.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"BG8VWu_hp":{"layout":["auto","auto"]}}}
 * @framerVariables {"IIsmGTaJn":"link","e8JeMkAVh":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramergZouczazT: React.ComponentType<Props> = withCSS(Component, css, "framer-Jx9jY") as typeof Component;
export default FramergZouczazT;

FramergZouczazT.displayName = "Buttons/ Email Button";

FramergZouczazT.defaultProps = {height: 51, width: 230.5};

addPropertyControls(FramergZouczazT, {IIsmGTaJn: {title: "Link", type: ControlType.Link}, e8JeMkAVh: {defaultValue: "hello@capable.com", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramergZouczazT, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})